/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
// import { CHECKBOX_TYPE, TEXT_TYPE } from 'Component/Field/Field.config';
import { FieldType } from '@scandipwa/scandipwa/src/component/Field/Field.config'
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import NewsletterCustomPopup from 'Component/NewsletterCustomPopup';
import PrivacyCheckboxLabel from 'Component/PrivacyCheckboxLabel';
import { ValidationInputType } from 'Util/Validator/Config';
import newsletterImg from './assets/newsletter.svg';

import './NewsletterCustom.style';

/** @namespace Pwa/Component/NewsletterCustom/Component/NewsletterCustomComponent */
export class NewsletterCustomComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isFormSubmitted: PropTypes.bool.isRequired,
        submittedMessage: PropTypes.element.isRequired,
        onNewsletterSubscribe: PropTypes.func.isRequired
    };

    state = {
        isPrivacyChecked: false,
        isMarketingChecked: false,
        privacyError: '',
        marketingError: '',
        isFormSubmitted: false,
        isLoading: false,
        error: '',
    }

    renderPrivacyLabel() {
        return (
            <PrivacyCheckboxLabel
                isRequired
                isNlCheckbox
                label={__('Dichiaro di aver preso visione dell’')}
                link={__('Informativa privacy')}
            />
        );
    }

    renderMarketingLabel() {
        return (
            <PrivacyCheckboxLabel
                isRequired
                isNlCheckbox
                label={__('Presto il consenso alle finalità di marketing diretto attraverso forme “tradizionali” e “automatizzate” di contatto (art. 130 c. 1 e 2 del D.lgs. 196/03 e s.m.i), come indicato al punto 4 del paragrafo “Finalità del trattamento e base giuridica” dell’')}
                link={__('Informativa privacy')}
            />
        );
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
    
        if (name === 'privacy_check') {
            this.setState({
                isPrivacyChecked: checked,
                privacyError: checked ? '' : 'Required'
            });
        } else if (name === 'marketing_check') {
            this.setState({
                isMarketingChecked: checked,
                marketingError: checked ? '' : 'Required'
            });
        }
    };


    handleSubmit = async (form, fields) => {
        const { isPrivacyChecked, isMarketingChecked } = this.state;

        if (!isPrivacyChecked || !isMarketingChecked) {
            this.setState({
                privacyError: !isPrivacyChecked ? 'Required' : '',
                marketingError: !isMarketingChecked ? 'Required' : ''
            });
            return;
        }

        try {
            this.setState({ isLoading: true, error: '' });
            await this.props.onNewsletterSubscribe(form, fields);
            this.setState({ isLoading: false, isFormSubmitted: true });
        } catch (error) {
            this.setState({ isLoading: false, error: 'An error occurred while subscribing.' });
        }
    };


    render() {
        const {
            isLoading, onNewsletterSubscribe, isFormSubmitted, submittedMessage
        } = this.props;
        const { error, privacyError, marketingError } = this.state;

        let content = (
            <div block="NewsletterCustom" elem="Content" className="neoNewsletter">
                <div>
                    <div block="title">
                        {__('Registrati alla nostra Newsletter')}
                    </div>
                    <div block="content">
                        <p block="info">
                            {__('Sconti, promozioni, novità e ispirazione: Abbiamo un mondo di cose da dirti')}
                        </p>
                        <Form
                            mix={{ block: 'NewsletterCustom', elem: 'Form' }}
                            key="confirm-account"
                            onSubmit={this.handleSubmit}
                            onError={this.handleCheckboxChange}
                        >
                            <div>
                                <label
                                    block="NewsletterCheckbox"
                                    elem="Row"
                                    events={{
                                        onChange: this.handleCheckboxChange
                                    }}
                                >
                                    <div block="NewsletterCheckbox" elem="Box">
                                        <Field
                                            attr={{
                                                id: 'privacy_check',
                                                name: 'privacy_check',
                                            }}
                                            mix={{ block: 'NewsletterCustom', elem: 'Checkbox', mods: { FooterNewsletter: true } }}
                                            validation={['checked']}
                                            validateOn={['onChange']}
                                            validationRule={{
                                                isRequired: true,
                                            }}
                                            //   addRequiredTag={true}
                                            type={FieldType.CHECKBOX}
                                            // label=
                                            events={{
                                                onChange: this.handleCheckboxChange
                                            }}
                                        />
                                        {this.renderPrivacyLabel()}
                                    </div>
                                    {privacyError && <p block="Field" elem="Message">{privacyError}</p>}

                                </label>
                                <label
                                    block="NewsletterCheckbox"
                                    elem="Row"
                                    events={{
                                        onChange: this.handleCheckboxChange
                                    }}
                                >
                                    <div block="NewsletterCheckbox" elem="Box">
                                        <Field
                                            attr={{
                                                type: FieldType.CHECKBOX,
                                                id: 'marketing_check',
                                                name: 'marketing_check',
                                            }}
                                            mix={{ block: 'NewsletterCustom', elem: 'Checkbox', mods: { FooterNewsletter: true } }}
                                            validation={['checked']}
                                            validateOn={['onChange']}
                                            validationRule={{
                                                isRequired: true,
                                            }}
                                            addRequiredTag={true}
                                            type={FieldType.CHECKBOX}
                                            // label={this.renderMarketingLabel()}
                                            events={{
                                                onChange: this.handleCheckboxChange
                                            }}
                                        />
                                        {this.renderMarketingLabel()}
                                    </div>
                                    {marketingError && <p block="Field" elem="Message">{marketingError}</p>}
                                </label>
                            </div>

                            <div block="NewsletterCustom" elem="Group">
                                <Field
                                    attr={{
                                        id: 'newsletter_email',
                                        name: 'newsletter_email',
                                        placeholder: __('Please enter your email address'),
                                        'aria-label': __('Email address'),
                                    }}
                                    mix={{ block: 'NewsletterCustom', elem: 'Input' }}
                                    type={FieldType.TEXT}
                                    // validation={ ['notEmpty', 'email'] }
                                    validateOn={['onChange']}
                                    validationRule={{
                                        isRequired: true,
                                        inputType: ValidationInputType.EMAIL,
                                    }}

                                // placeholder={ __('Enter your email address') }
                                // aria-label={ __('Email address') }
                                />
                                <button
                                    type="submit"
                                    mix={{ block: 'NewsletterCustom', elem: 'Button' }}
                                >
                                    {__('Subscribe')}
                                </button>
                            </div>

                        </Form>
                    </div>
                </div>
                <img src={newsletterImg} alt="newsletter" />
            </div>
        );

        if (isFormSubmitted && submittedMessage) {
            content = submittedMessage;
        }

        return (
            <div block="NewsletterCustom">
                <Loader isLoading={isLoading} />
                <NewsletterCustomPopup />
                {content}
            </div>
        );
    }
}

export default NewsletterCustomComponent;
